.pdsec1cont{
    width: 100%;
    display: flex;
    gap: 9px;
}

.pdsec1d1{
    width: 50%;
    max-height: 446px;
}

.alltranport{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.totalcost2{
    font-family: Poppins;
font-size: 14px;
font-weight: 600;
line-height: 27px;
color: #000000;
}

.chiwithma{
    display: flex;
    align-content: center;
    justify-content: space-between;
    
}
.chiwithma p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    
}
.chiwithma span{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    
}

  


.tranpoorwrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.IoIosArrowDownwrap{
    min-width: 20px;
}

.IoIosArrowDown{
    font-size: 18px !important;
}
.tranpoorwrap h4{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    max-width: 70%;
    width: 100%;
color: #000000;    
}
.tranpoorwrap p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
color: #000000;    
}


.starwrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.starwrap p{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
color: #000000;    
}
.pdsec1images{
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    gap: 9px 9px;
    width: 50%;
}
.pdsec1images img{
   width: 100%;
   height: 218px;
}
.pdsec1images img:nth-child(2){
 border-radius: 0px 20px 0px 0px;
}
.pdsec1images img:nth-child(4){
 border-radius:0px 0px 20px 0px;
}

/* for secstion2  */

.pdSec2wrap{
    display: flex;
gap: 30px;
padding: 40px 0px;
justify-content: space-between;
}

.pdSec2Right{
    max-width: 464px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 17px;
}
.pdSec2Right .sec2ritop{
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    width: 100%;
height: 149px;
border-radius: 12px ;
display: flex;
flex-direction: column;
gap: 16px;
padding: 22px;
box-shadow: 0px 4px 4px 0px #00000040;

}
 .sec2ritop .s2ttop{
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
}

.pds2left{
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.pds2left .pdlepar1{
  display: flex;
  gap: 4px;
  font-family: Poppins;
font-size: 24px;
font-weight: 600;
line-height: 20px;

color: #000000;
}
.pds2left .pdlepar1 p{
font-size: 40px;

}
.pds2left .pdlepar1 span{ 
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top:7px;
    text-align: left;
color: #A4A1A1;    
}
.pdlepar1 div{
    margin-top: 7px;
}
.pds2left .pdlepar2{ 
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
color: #7D7C7C;    
display: flex;
gap: 10px;
align-items: flex-end;

}

.pds2left  .pdlepar2 span{ 
    font-size: 24px;
    text-decoration: line-through;

}
.pds2topright{
    display: flex;
    align-items: center;
    gap: 6px;
}
.pds2topright span:nth-child(2){
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
color: #008753;    
}
.pds2topright span:nth-child(3){
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
color: #7D7C7C;    
}

.line1 {
    border: 1px solid;
    width: 100%;
    border-image-source: linear-gradient(90deg, rgba(225, 222, 222, 0.3) 0%, #7B7979 50%, rgba(227, 226, 226, 0.3) 95.4%);
    border-image-slice: 1;
}

.sec2ritop button{
    width: 100%;
height: 51px;
border-radius: 10px;
background: #CCF32F;


}
.sec2ritop button span{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
color: #000000;    
}

/* Default styles for .formdetail */
.formdetail {
    height: fit-content;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    gap: 14px;
    position: relative; /* Default to relative */
    max-width: 464px;
    width: 100%;
    padding: 20px;
    z-index: 10;
    transition: position 0.3s, top 0.3s; /* Smooth transition */
  }
  
  /* Fixed positioning added via JavaScript */
  .fixed-position {
    position: fixed;
    top: 130px; /* Adjust as needed */
  }
  

.showformwrap {
    background: #00000066;
    position: fixed;
    z-index: 30;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: flex-end;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .showformwrap.active {
    opacity: 1;
    z-index: 1000;
  }
  
  .formdetail2 {
    position: absolute;
    z-index: 50;
    margin: 0px 10px;
    height: fit-content;
    border-radius: 12px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 18px 23px;
    transform: translateY(100%);
    animation: slideUp 0.5s ease-in-out forwards;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .RxCross2d{
  text-align: right;
  }
.formdetail h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
color: #000000;    

}
.formdetail2 h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
color: #000000;    

}
.formdetail form{
   display: flex;
   flex-direction: column;
   gap: 9px;
}
.formdetail2 form{
   display: flex;
   flex-direction: column;
   gap: 9px;
}
.formdetail form label{
    width: 100%;
    height: 54px;
    border-radius: 6px ;
    background: #F4EFEF00;
    border: 1px solid #D4D3D3;
    display: flex;
    flex-direction: column;
    padding:9px 14px;
    
}
.formdetail2 form label{
    width: 100%;
    height: 54px;
    border-radius: 6px ;
    background: #F4EFEF00;
    border: 1px solid #D4D3D3;
    display: flex;
    flex-direction: column;
    /* gap: 7px; */
    padding:9px 14px;
    
}
.formdetail form label p{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
color: #00000069;    
}
.formdetail2 form label p{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
color: #00000069;    
}
.formdetail form label p span{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #FF4747;
    text-align: left;
    
}
.formdetail2 form label p span{
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #FF4747;
    text-align: left;
    
}
.formdetail form label input{
   width: 100%;
   background-color: transparent;
   outline: none;
   border: none;
   font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;

}
.formdetail2 form label input{
   width: 100%;
   background-color: transparent;
   outline: none;
   border: none;
   font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;

}

.dohalf{
    display: flex;
    align-items: center;
    gap: 12px;
}

.phonenumbeint{
    max-width: 91px;
    width: 100%;
    height: 49px;
    padding: 14px;
border-radius: 6px ;
border: 1px solid #D4D3D3;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: #000000C7;

}
.myphone{
    /* max-width: 313px; */
    width: 100%;
    height: 49px;
    border-radius: 6px ; 
    border: 1px solid #D4D3D3;
    padding: 14px;
    font-size: 16px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: #000000C7;

}
.Traveldate{
    /* max-width: 194px; */
    width: 50%;
    height: 49px;
    border-radius: 6px ; 
    border: 1px solid #D4D3D3;
    padding: 14px;
    font-size: 16px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: #000000C7;

}
.Duration{
    /* max-width: 211px; */
    width: 50%;
    height: 49px;
    border-radius: 6px ; 
    border: 1px solid #D4D3D3;
    padding: 14px;
    font-size: 16px;
font-weight: 500;
line-height: 20px;
text-align: left;
color: #000000C7;

}

.textaremesge{
    max-width: 416px;
    width: 100%;
max-height: 83px;
height: 100%;
border-radius: 6px ;
background-color: #D9D9D900;
border: 1px solid #D4D3D3;
padding: 15px;
resize: none;

}

.requeeqebtn{
    width: 100%;
border-radius: 10px;
background: #CCF32F;
height: 58px;
font-family: Poppins;
font-size: 16px;
font-weight: 600;
line-height: 20px;
color: #000000;
}

.pdSec2left{
    max-width: 763px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.pdSec2left h2{
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    text-align: left;
color: #000000;  
max-width: 632px;  
}

.pds22leftop{
    display: flex;
    gap: 24px;
}

.pds22leftop .pdsltf{
    min-width: 81px;
    height: 39px;
    border-radius: 20px ;
    background: #CCF32F;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
color: #000000;
display: flex;
align-items: center;
justify-content: center;        
}

.pdltse{
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 18px;
}

.pdflsfir{
    display: flex;
    align-items: center;
    gap: 5px;
}

.pdflsfir span{
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
color: #C0B9B9;    
}
.pdflsfir div{
  display: flex;
  flex-direction: column;
}
.pdflsfir div p:nth-child(1){
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
color: #9E9E9E;    
}
.pdflsfir div p:nth-child(2){
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
color: #7D7C7C;    
}

.verline{
    width: 2px;
height: 31px;
background-color: #D9D9D9;
}

.line2{
    border: 1px solid;
    width: 100%;
    border-image-source: linear-gradient(90deg, rgba(225, 222, 222, 0.24) 0%, rgba(123, 121, 121, 0.8) 50%, rgba(227, 226, 226, 0.24) 95.4%);
    border-image-slice: 1;
}

.addmargintop{
    margin-top: 80px;
}

.triphigh{
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.triphigh h3{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
color: #000000;    
}
.triphigh ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 743px;
    width: 100%;
    position: relative;
}

.triphigh ul li{
    font-family: Poppins;
    font-size: 16px;
    list-style:none; 
    font-weight: 600;
    line-height: 20px;
    text-align: left;
color: #7C7A7A;    
}

.triphigh ul li::before {
    content: "•"; 
    position: absolute;
    left: -14px;
    font-size: 18px;
    color: #7C7A7A; 
}

.toogleBtns{
    width: 100%;
height: 55px;
background: white;
border: 1px solid #E1DFDF;
display: flex;
align-items: center;
gap: 7px;
margin: 20px 0px;
padding: 0px 10px;

}

.samebtnproepty{
    width: 50%;
height: 43px;
border-radius: 10px ;
background: #E8EAE1;

}
.samebtnproepty span{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
color: #878686;    
}

.curtoglebtn{
    background: #CCF32F;

}
.curtoglebtn span{
    background: #CCF32F;
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
color: #000000;

}

.pdimg3{
    width: 100%;
    max-height: 214px;
}

.arivepotalDiv{
    border: 1px solid #E1DFDF;
width: 100%;
border-radius: 10px;
padding: 15px 23px;
display: flex;
flex-direction: column;
}
.staywrap{
    border: 1px solid #E1DFDF;
width: 100%;
border-radius: 10px;
padding: 15px 23px;
display: flex;
/* gap: 10px; */
flex-direction: column;
}

.singstary{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-end;
}

.stayleft{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.stayleft p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    
}

.stayright{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}

.arivepotalDiv nav{
    display: flex;
    align-items: center;
    gap: 15px;
}

.daytag{
    min-width: 56.08px;
height: 27px;
border-radius: 20px ;
background: #CCF32F;
display: flex;
align-items: center;
justify-content: center;
font-family: Poppins;
font-size: 11px;
font-weight: 600;
line-height: 16.5px;

}

.arivtext{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 27px;
text-align: left;
color: #000000;
/* max-width: 60%; */
}

.lineh2{
    border: 1px solid #A8A7A7;
    width: 20px;
height: 0px;
top: 1555px;
left: 378px;
gap: 0px;
border: 1px 0px 0px 0px;
transform: rotate(90deg);

}

.totalcost{
    font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
color: #000000;
max-width: 154px;
width: 100%;
}

.navipack{
justify-content: space-between;
width: 100%;
}

.divwrapck{
    width: calc(100% - 155px)
}

.aftetext{
    font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 27px;
text-align: left;
color: #000000;
}

.pdsefistsec{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.pdsefistsec h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
color: #969595;    
}
.pdsefistsec h4{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
color: #000000;    
}
.totaltrans{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.totaltrans p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
color: #000000;    
}
.pdsefistsec label{
   position: relative;
   margin-top: 30px;
   z-index: 200 !important;
}

.shadowdiv{
    width: 100%;
height: 55px;
border-radius: 10px ;
background: #FFFFFF;
border: 1px solid #E1DFDF;
box-shadow: 0px 4px 4px 0px #0000000D;
display: flex;
align-items: center;
padding-left: 20px;
z-index: 10;

}
.shadowdiv p{
    font-family: Poppins;
font-size: 18px;
font-weight: 500;
line-height: 27px;
text-align: left;
color: #000000;
}

.fromtag{
    width: 48px;
height: 27px;
border-radius: 10px ;
background: linear-gradient(268.45deg, rgba(217, 217, 217, 0) 0.78%, #94EE8C 98.33%);
position: absolute;
top: -20px;
font-family: Poppins;
font-size: 10px;
display: flex;
align-items: center;
justify-content: center;
font-weight: 500;
line-height: 15px;
color: #008753;
z-index: -10;
}

.pdsesecondimage{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.pdsesecondimage .rowiamges{
 display: flex;
 align-items: center;
 gap: 14px;
}

.pdsesecondimage .rowiamges img{ 
 width: 50%;
 max-height: 172px;
}


.rec92img{
    max-height: 80px;
    height: 100%;
    width: 100%;
}

.detailcopy1{
    max-height: 214px;
    width: 100%;
}

.arvidiv{
    width: 100%;
height: 57px;
border-radius: 10px ;
border: 1px solid #E1DFDF;
display: flex;
align-items: center;
padding: 14px 18px;
gap: 15px;

}

.detailcopy2{
    max-width: 830px;
    width: 100%;
    margin: 0 auto;
}
.arvidiv .daytag{
    width: 56.08px;
    height: 27px;
    border-radius: 20px ;
    background: #CCF32F;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 600;
    line-height: 16.5px;
color: #000000;        

}
.arvidiv h3{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
color: #000000;     

}
.arvidiv .linevert{
    width: 20px;
    transform: rotate(90deg);
    border: 1px solid #A8A7A7;    

}
.arvidiv .totacost{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
color: #000000;    

}

.includedSec{
    display: grid;
    max-width: 90%;
    grid-template-columns: repeat(3 , 1fr);
justify-content: space-between;
gap: 30px;
}

.sinincluddiv{
    display: flex;
    align-items: center;
    gap: 6px;
}
.sinincluddiv img{
    max-width: 20px;
    max-height: 20px;
}
.sinincluddiv span{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
}

.destinydetailssec{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.packdetailInsidesec {
    display: flex; 
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
  }
.staycontewrap {
    display: flex; 
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }

  .totalstay{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .singldesity {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .topmargint{
    margin-top: 15px;
  }
  
  .pdsec1aniwrap{
    display: none;
  }
  .singhersmalwidth{
    width: 100%;
    max-height: 80vh;
    height: 100%;
  }
  .singhersmalwidth img{
    width: 100%;
height: 70vh;
    padding-bottom: 20px;
  }

  @media (max-width:600px) {
    .singhersmalwidth img{
        width: 100%;
    height: 50vh;
        padding-bottom: 20px;
      }
  }

  .daynigratingwrap{
    display: none;
  }

  .quersmalwidth{
    width: 100vw;
    height: 120px;
    background-color: white;
    position: fixed;
bottom: 0px;
display: none;
z-index: 110;
left: 0;
right: 0;
border-top: 2px solid #E3E3E3;
align-items: center;
justify-content: center;
  }

  .sendenqbtn{
    width:90% ;
    height: 61px;
    background: #CCF32F;
    border-radius: 10px ;  
    z-index: 1000 !important;
  }
  .sendenqbtn span{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
color: #000000;    
  }

  .staywrapsd{
    width: 100%;
height: fit-content;
border-radius: 10px ;
/* background: #E9E9E9; */
border: 1px solid #E1DFDF;
padding: 0px 18px;

  }

  .staywrapsd{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }




  @media (max-width:1024px) {
    .pdsec1cont{
        display: none;
    }
    .showformwrap{
        display: flex;
    }
    .formdetail2{
        display: flex;
    }
    .quersmalwidth{
        display: flex;
        z-index: 10000 !important;
    }
    .pdsec1aniwrap{
        display: flex;
    }
    .pdSec2Right{
        display: none;
    }
    .pdSec2wrap{
    padding: 10px 0px;
    }
    .triphigh ul{
     
        max-width: 98%;
        width: 100%;
      
    }
    .pdSec2left{
        max-width: 100%;
        gap: 15px;

    }
    .pdSec2left h2{
        font-size: 28px; 
        line-height: 38px; 
    max-width: 100%;  
    }
    
    .daynigratingwrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .dnihsamlle{
        font-family: Poppins;
font-size: 13px;
font-weight: 500;
line-height: 19.5px;
text-align: left;
color: #9E9E9E;
    }
    .ratintotal{
        font-family: Poppins;
font-size: 13px;
font-weight: 500;
line-height: 19.5px;
text-align: left;
color: #9E9E9E;
display: flex;
align-items: center;
gap:6px;
    }
    .ratintotal span{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
color: #008753;        
    }


  }

  @media (max-width:800px) {
    .includedSec{
        max-width: 100%;
    } 

    .tranpoorwrap p{
        font-size: 13px;
        }
    .arivtext{
        font-size: 14px;
        line-height: 24px;
    }
    .totalcost{
        font-size: 14px;
        line-height: 24px;
        width: 140px;
    }
  }

  @media (max-width:650px) {
    
.totalcost{
font-size: 14px;
line-height: 24px;
max-width: 124px;
}
  }

  @media (max-width:550px) {
    .pdSec2left h2{
       font-size: 24px;
       line-height: 34px;
    }
    .tranpoorwrap p{
    font-size: 12px;
    }
    
    
    .sinincluddiv span{
       font-size: 10px;
       line-height: 20px;
    }
    .includedSec{
       gap: 20px;
    }
    .sinincluddiv img{
        max-width: 15px;
        max-height: 15px;
    }
    .triphigh h3{
      font-size: 20px;
    }
    .triphigh ul{
       
        max-width: 95%;
        
    }
    
    .triphigh ul li{
       font-size: 14px;
       line-height: 20px;
    }
    .samebtnproepty span{
       font-size: 14px;
    }
    .arivtext{
        font-size: 14px;
        line-height: 24px;
    }
    
.totalcost{
 font-size: 14px;
 line-height: 24px;
}

.aftetext{
  font-size: 14px;
  line-height: 24px;
}

.pdsefistsec h3{
   font-size: 16px;
   line-height: 24px;
}
.pdsefistsec h4{
  font-size: 14px;
  line-height: 24px;
}
.shadowdiv p{
   font-size: 16px;
   line-height: 24px;
}
.pdltse{
    display: grid;
    width: 80%;
    grid-template-columns: repeat(2 , 1fr);
    gap: 10px;
  
    }

    .arivepotalDiv{
       
    padding: 15px 13px;
  
    }
    
  }

  @media (max-width:500px) {
    .includedSec{
        display: flex;
        flex-direction: column;
    }
    /* .totalcost{
        max-width: 90px;
        font-size: 10px !important;
        } */
    .pdSec2left h2{
      font-size: 20px;
      line-height: 30px;
    }

    .pds22leftop{
        display: flex;
        align-items: flex-start;
    }
    .arivepotalDiv{
        padding: 15px 10px !important;
    }

  }

  @media (max-width:460px) {
    .arivepotalDiv{
padding: 15px 16px;
    }
    .staywrap{
padding: 15px 16px;
    }
    .arivepotalDiv nav{
        gap: 10px;
    }
    .arivtext{
        font-size: 10px;
        line-height: 18px;
    }
    .totalcost{
        font-size: 12px;
        line-height: 20px;
        width: 110px;

    }
  }

  @media (max-width:400px) {
    .pdflsfir div p:nth-child(1){
        font-size: 10px;
        line-height: 20px;
    }
    .pdflsfir div p:nth-child(2){
    font-size: 11px;
    line-height: 21px;
    }
    
.pds22leftop{
    gap: 12px;
}

.aftetext{
    font-size: 12px;
    line-height: 22px;
}

.pdsefistsec h3{
    font-size: 14px;
    line-height: 23px;
}
.pdsefistsec h4{
    font-size: 12px;
    line-height: 23px;
}
    
.shadowdiv p{
    font-size: 14px;
    line-height: 23px;
}
  }