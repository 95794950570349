.hse2wrap{
    padding: 20px 0px;
    width: 100%;
    position: relative;
}

.hse2cont{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 14px;
} 

.appseleft{
    padding: 50px 0px;
}

.hs2top{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.hs2top .cantafort{
    width: fit-content;
    height: fit-content;
    border-radius: 35px ;
    background: #262323; 
    display: flex;
    align-items: center;
    justify-content: center;
 padding: 14px 30px;
}
 .cantafort span{
    font-family: Inter;
    font-size: 34px;
    font-weight: 700;
    line-height: 44px;
    text-align: center;
color: #FFFFFF;    
}

.suubtitle{
    font-family: Inter;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 28px;
color: black;    
}
.hs2top h2{
    font-family: Inter;
font-size: 40px;
font-weight: 700;
line-height: 48.41px;
text-align: center;
color: #000000;
}

.singswipe{
    max-width: 584.5px;
    width: 100%;
    height: 494px;
    border-radius: 20px ;
    box-shadow: 0px 3px 20px 0px #00000014;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    justify-content: space-between;
    margin: 0 auto;
padding-bottom: 20px;    
}
 .swipbg{
    width: 100%;
   max-height: 260px; 
border-radius: 20px 20px 0px 0px;
}

.bestsellertag{
    position: absolute;
    right: 30px;
    top:22px;
    width: 114px;
height: 34px;
border-radius: 8px ;
background: #000000;
display: flex;
align-items: center;
justify-content: center;


}
.bestsellertag span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24.2px;
color: #FFFFFF;    
}


.swimidCont{
    padding: 0px 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}
.swimidCont .swmidcleft{
 display: flex;
 flex-direction: column;
 gap: 16px;
}
.swimidCont .swmidcleft h3{
    font-family: Inter;
    font-size: 20px;
    max-width: 254px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: left;
color: #000000;    
}
.swimidCont .swmidcleft img{
width: 186px;
}

.swimidCont p{
    display: flex;
    align-items: center;
    gap: 8px;
}

.mySwiper{
  width: 100%;
  height: fit-content;
  padding: 20px 0px !important;
}

.eahpersonwap{
    display: flex;
    padding-left: 40px;
    flex-direction: column;
gap: 2px;
}

.checkdbtns{
    max-width: 510px;
    width: 100%;
height: 51px;
border-radius: 7px ;
background: #CCF32F;
margin: 0 auto;

}
.checkdbtns span{
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
color: #000000;    
}
.swiper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
  }
  
  .left-btn,
  .right-btn {
    cursor: pointer;
    width: 400px; 
    height: 40px;
    background-color: red;
  }
  
  .left-btn {
    position: absolute;
    left: -50px; /* Adjust position */
    z-index: 10;
  }
  
  .right-btn {
    position: absolute;
    right: -50px; /* Adjust position */
    z-index: 10;
  }

  .perperontext{
    font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 32px;
text-align: left;
color: #000000;
  }
  .perperontext span{
    font-family: Poppins;
font-size: 15px;
font-weight: 500;
line-height: 22px;
text-align: left;
color: #969494;
  }
 
  .daynighspan{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #858396;
  }

  @media (max-width:1340px) {
    .singswipe{
        max-width: 500px;
        height: 504px;
    }
    .checkdbtns{
       width: 90%;
    }
  }
  @media (max-width:1160px) {
    .singswipe{
        max-width: 560px;
        height: 504px;
    }
    .checkdbtns{
       width: 90%;
    }
  }
@media (max-width:1060px) {
    .cantafort span{
      
        font-size: 26px;
        line-height: 36px;
    
    }
}

@media (max-width:860px) {
    .cantafort span{
      
        font-size: 20px;
        line-height: 30px;
    
    }
}
  @media (max-width:750px) {
    .singswipe{
        max-width: 560px;
        height: 510px;
        padding-bottom: 24px;
    }
    .swiperbtns{
        display: none;
    }
   
    .hs2top h2{
    font-size: 30px;
    }
    

  }
  @media (max-width:680px) {
    .cantafort span{
      
        font-size: 16px;
        line-height: 26px;
    
    }
}
  @media (max-width:550px) {
    .swiperbtns{
        display: none;
    }
   
    .hs2top h2{
    font-size: 20px;
    line-height: 32px;
    }
    .swimidCont .swmidcleft h3{
        font-size: 18px;
    }
    
   
  }

  @media (max-width:500px) {
    .swimidCont{
        display: flex;
        flex-direction: column-reverse;
    }
  }
