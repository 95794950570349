.servicewrap{
    width: 100%;
    position: relative;
}

.waterwrap{
    max-height: 480px;
    height: 100%;
    max-width: 380px;
    width: 100%;
border-radius: 20px;
}

.waterwrap img{
    width: 380px;
     height: 480px;
     border-radius: 20px;
}

.servicecont{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px 14px;
}
.servicecont h3{
    font-family: Playfair Display;
font-size: 42px;
font-weight: 700;
line-height: 55.99px;
color: #000000;
}

.serviceimages{
    margin: 0 auto;
    display: flex;
    gap: 13px;
}
.serviceimages .rec3{
   max-width: 792px;
   width: 100%;
}

.servirwoinmg {
    display: flex;
    flex-direction: column;
    gap: 17px;

}
.servirwoinmg img {
   max-width: 389px;

}

.seeMorebtn{
    width: 174px;
height: 47px;
background: #CCF32F;
border: 2px solid #CCF32F;
display: flex;
align-items: center;
gap: 10px;
justify-content: center;
border-radius: 22px;

}
.seeMorebtn span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
color: #141841;    
}

/* for ready banner  */
.readywrap{
    position: relative;
    width: 100%;
    background: #112F38;

}

.readycont{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    padding: 85px 14px;
    position: relative;
}

.readycont button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 185px;
height: 47px;
background: #CCF32F;
border: 2px solid #CCF32F;
border-radius: 22px;
}
.readycont button span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
        
}

.readycont h3{
    font-family: Playfair Display;
    font-size: 48px;
    font-weight: 700;
    line-height: 63.98px;
    text-align: center;
color: #FFFFFF;    
}
.readycont button{
    width: 185px;
    height: 47px;
    background: #CCF32F;
    border: 2px solid #CCF32F;    
}

.upperimg{
    position: absolute;
    top: 0;
    /* transform: translateX(50%); */

}

@media (max-width:1230px) {
    .serviceimages .rec3{
        max-width: 692px;
        width: 100%;
     }
     
}
@media (max-width:1120px) {
    .serviceimages .rec3{
        max-width: 500px;
        width: 100%;
     }
     
     .servirwoinmg img {
        max-width: 300px;
     
     }
}

@media (max-width:1024px) {
    
.waterwrap{
    max-height: 450px;
    max-width: 370px;
}

.waterwrap img{
    width: 370px;
     height: 450px;
}
.wateaccont .watername{
    font-size: 14px !important;
    line-height: 24px !important;
}
.wateaccont .waterddestiny{
    font-size: 14px !important;
    line-height: 24px !important;
}

}

@media (max-width:850px) {
    .serviceimages{
        display: flex;
        flex-direction: column;
       
    }
    .servirwoinmg img {
        max-width: 500px;     
     }

     .waterwrap{
        max-height: 330px;
        max-width: 330px;
    }
    
    .waterwrap img{
        width: 330px;
         height: 330px;
    }
  
     
}



@media (max-width:430px) {
   
    .serviceimages .rec3{
       max-width: 90vw;
    }
    .servirwoinmg img{
        max-width: 90vw;
    }
     
}

@media (max-width:550px) {
    .readycont h3{
    
        font-size: 36px;
        line-height: 46px;
      
    }
}

/* water activity  */
.waterAcwrap{
    width: 100%;
    position: relative;
}

.wateracticont{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;

}

.wateracticont h2{
   font-family: Playfair Display;
font-size: 60px;
font-weight: 700;
line-height: 79.98px;
color: #000000;
}

.wateracitems{
    display: flex;
    width: 100%;
    gap: 24px;
     justify-content: center;
     padding: 0px 18px;
    
}


.seemorebtn{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 174px;
height: 47px;
border: 2px solid #CCF32F;
background: #CCF32F;
justify-content: center;
border-radius: 22px;

}
.seemorebtn span{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
color: #141841;    
}


/* frequest questions  */
.freqwrap{
    position: relative;
    width: 100%;
}

.freqcont{
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin: 0 auto;
    align-items: center;
    padding: 70px 14px;
}

.frqtop{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;

}
.frqtop h2{
    font-family: Inter;
    font-size: 56px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.02em;
    text-align: center;
color: #000000;    
}
.frqtop p{
    font-family: Inter;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
color: #707070;    
text-align: center;

}

.questions{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    
}
.questions .singques{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 20px 10px;
  border-bottom: 2px solid #DEDEDE;
}

.queitonaprt{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.queitonaprt p{
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 46px;
    text-align: left;
color: #000000;    
}
.queitonaprt img{
 max-width: 80px;
 max-height: 80px;
}

.anspart{
    max-width: 944px;
    font-family: Inter;
font-size: 22px;
font-weight: 400;
line-height: 36px;
text-align: left;
color: #786F77;
}

.singques {
    margin-bottom: 10px;
  }
  
  .queitonaprt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .anspart {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease, padding 0.4s ease;
    padding: 0 15px;
    background-color: #f9f9f9;
    border-radius: 4px;
  }
  
  .anspart.active {
    max-height: 200px; /* Adjust this value depending on the content */
    padding: 15px;
  }
  
  .loadmorebtn{
    max-width: 402px;
    width: 100%;
height: 76px;
border-radius: 10px ;
background: #CCF32F;

  }
  .loadmorebtn span{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
color: #000000;    
  }

  .wateaccont{
    position: absolute;
    bottom: 20px;
    left: 20px;
     display: flex;
     flex-direction: column;
     gap: 0px;
     padding: 0px 2px 0px 0px;
  }
  .wateaccont .watername{
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
 color: white;    
  }
  .wateaccont .waterddestiny{
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
 color: white;    
  }

  @media (max-width:1050px) {
    .wateaccont p{
        font-size: 18px;
    }
  }
  
@media (max-width:800px) {
    .frqtop h2{
        font-size: 40px ;  
    }
    .frqtop{
        gap: 10px;

    }
}

@media (max-width:700px) {
    .queitonaprt p{
        font-size: 26px;
    
    }
}
@media (max-width:550px) {
    .queitonaprt p{
        font-size: 18px;
        line-height: 28px;
    
    }
    .queitonaprt img{
        max-width: 40px;
        max-height: 40px;
       }
       
       .anspart{
       font-size: 16px;
       line-height: 26px;
    }
}

@media (max-width:800px) {
    .frqtop h2{
        font-size: 30px ;
        line-height: 40px;  
    }
    .frqtop p{
      font-size: 16px;

    }
    .frqtop{
        gap: 0px;

    }
    
}


@media (max-width:970px) {

     
     .wateracticont h2{
   font-size: 42px;
     }
     .wateaccont p{
        font-size: 15px !important;
        line-height: 24px !important;
    }
}

@media (max-width:680px) {
   .wateracitems{
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   
   .waterwrap{
    max-height: 430px;
    max-width: 350px;
}

.waterwrap img{
    width: 450px;
     height: 430px;
}
 
}

@media (max-width:550px) {
    .wateracticont h2{
        font-size: 28px;
        line-height: 38px;
        text-align: center;
          }
}
@media (max-width:370px) {
    .wateracticont h2{
        font-size: 24px;
        line-height: 33px;
        text-align: center;
          }
}