.placwrap{
    position: relative;
    background-color: white;

}


.allplacesCon{
max-width: 1440px;
width: 100%;
margin: 130px auto 0px auto;
display: grid;
padding: 20px 18px;
grid-template-columns: repeat(3 , 1fr);
gap: 30px;
}

.sindesit{
    max-width: 390px;
    width: 100%;
    cursor: pointer;
    max-height: 404px;
    height: 100%;
    position: relative;
}
.sindesit img{
    max-width: 390px;
    width: 100%;
    max-height: 404px;
    height: 100%;
    border-radius: 10px;
}
.sindesit .sdecont{
    position: absolute;
    bottom: 20px;
    left: 20px;
}
.sindesit .sdecont p{
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
color: #FFFFFF;    
}


.popupwrap{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    /* height: 100vh; */
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

}

.allcontainer{
    position: relative;
}

.popcont{
max-width: 1100px;
width: 100%;
max-height: 600px;
height: fit-content;
background-color: white;
border-radius: 20px;
padding: 20px;
overflow-y: scroll !important;
 
}

.popcont nav{
    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.crroimg{
    font-size: 32px;
    cursor: pointer;

}
/* Zoom In Animation CSS */
.popupwrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .popupwrap.zoom-in {
    opacity: 1;
  }
  
  .popcont {
    background: white;
    padding: 20px;
    border-radius: 8px;
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
  }
  
  .popcont.zoom-in {
    transform: scale(1);
  }
  

  .imagesplace{
    display: flex;
   align-items: center;
   gap: 20px;
    
  }

  .contimages{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

  }
  .imagesplace img{
   width: 90%;
   height: 100%;
   height: 300px;
   margin: 0 auto;
  }
  .placcontent{
    font-family: Poppins;
font-size: 16px;

font-weight: 500;
line-height: 27px;
text-align: center;
color: #2B2B3D;
  }
@media (max-width:750px) {
    .allplacesCon{
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        }
        
}
@media (max-width:450px) {
    .allplacesCon{
        display: grid;
        grid-template-columns: repeat(1 , 1fr);
        }
        .sindesit img{
            max-width: 390px ;
            width: 100% ;
            height: 300px;
        }
}